.footer {
    background-color: var(--text-warning);
    color: white;
    padding: 10px;
    text-align: center;
}

.footer.footer-login {

    bottom: 0;
    width: 100%;
    left: 0;
}

.footer-top {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.social-icons-box {
    display: flex;
    flex-wrap: wrap;
    /* position: absolute;
    right: 10px; */
    flex: 1 1;
    justify-content: flex-end;
}

.social-icon {
    background-color: var(--text-primary);
    height: 40px;
    width: 40px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-icon:not(:last-child) {
    margin-right: 5px;
}

.social-icon img {
    height: 30px;
}

.footer-links {
    flex: 1 1;
}

.footer-links .nav-link {
    color: var(--text-primary);
    font-weight: bold;
    font-size: 16px;
}

.support-detail {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
}

.footer-bottom {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.footer-bottom img {
    max-height: 30px;
    max-width: 100px;
    margin-right: 10px;
}

.secure-logo {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    line-height: normal;
}

.footer-bottom .secure-logo img {
    max-height: 50px;
}

.footer-text {
    text-align: center;
}

.support-detail {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    flex-direction: column;
    flex: 1 1;
}

@media screen and (max-width: 768px) {

    .footer-links,
    .support-detail,
    .social-icons-box {
        flex: initial;
    }

    .footer-links {
        width: 100%;
        text-align: center;
    }

    .support-detail {
        width: 100%;
        margin-top: 15px;
    }

    .footer-links,
    .support-detail,
    .social-icons-box {
        flex: initial;
    }

    .footer-links ul {
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        color: white;
    }

    .footer-links ul li {
        width: 50%;
    }

    .social-icons-box {
        width: 100%;
        justify-content: center;
        position: static;
        position: initial;
        margin-top: 15px;
    }

    .secure-logo {
        width: 100%;
    }

    .secure-logo~div {
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .footer-links .nav-link {
        padding: 3px;
        text-decoration: underline;
        font-size: 12px;
    }

    .footer-text {
        margin-top: 5px;
    }

}